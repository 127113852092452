<template>
        <p v-if="error">
            <NotFound />
        </p>
        <div v-else class="about-wrapper">
          <div class="about-content">
            <p class="about-entry">{{ about.entryText }}</p> 
            <p class="about-small">{{about.content}}</p>
            <div class="about-bottom">
              <div class="social-media-icons">
                  <a :href="'mailto:' + about.email"><b-icon icon="envelope" variant="dark" font-scale="2"></b-icon></a>
                  <a :href="about.twitterURL" target="_blank"><b-icon icon="twitter" variant="dark" font-scale="2"></b-icon></a>
                  <a :href="about.igURL" target="_blank"><b-icon icon="instagram" variant="dark" font-scale="2"></b-icon></a>
              </div>
              <p class="about-disclaimer">{{about.disclaimer}}</p>
            </div>    
          </div>
        </div>
</template>

<script>
    import NotFound from '@/components/NotFound'


    export default {
        name: 'About',
        components:{
          NotFound
        },
        data () {
            return {
                about: [],
                error: null,
                api: process.env.VUE_APP_ALTERED_ROOT_API,
                headers: {'Content-Type': 'application/json'}
            }
        },
        methods: {
            parseJSON: function (resp) {
            return (resp.json ? resp.json() : resp);
            },
            checkStatus: function (resp) {
            if (resp.status >= 200 && resp.status < 300) {
                return resp;
            }
            return this.parseJSON(resp).then((resp) => {
                throw resp;
            });
            }
        },
        async mounted () {
            try {
            const response = await fetch(this.api + "/about", {
                method: 'GET',
                headers: this.headers,
            }).then(this.checkStatus)
                .then(this.parseJSON);
            this.about = response
            } catch (error) {
              this.error = error
            }
        }
    }


</script>

<style scoped>

.social-media-icons{
  max-width: 150px;
  margin: 20vh auto 5vh 0;
}

.social-media-icons a{
  padding-right: 15px;
}

.about-disclaimer{
  margin: 0;
  padding-bottom: 2.5vh;
}

.about-content{
  width: 90vw;
  margin: 0 auto 0 auto;
}

.about-wrapper{
  background-color:#fdfd00;
  min-height: 100vh;
}

.about-entry{
  font-size: 1.875em;
  line-height: 1.23333;
  padding-top: 35vh;
}

.about-small{
  font-family: 'TIActuBetaMono-Regular_web', 'Consolas', 'Andale Mono', monospace;
}

@media screen and (min-width: 800px) {
  .about-bottom{
    padding-top: 10vh;
  }
}

@media screen and (min-width: 700px) and (max-width:799px) {
  .about-entry{
    padding-top: 35vh;
  }
}

@media screen and (max-width: 699px) {
  .about-entry{
    font-size: 1.3em;
    padding-top: 25vh;
  }
}

</style>